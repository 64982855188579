<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div class="col">
          <div class="toolbar-title"></div>
        </div>
        <div class="col-auto">
          <el-button
            size="small"
            type="primary"
            @click="handleAddShopAssistant"
          >
            添加管理员
          </el-button>
        </div>
      </template>
      <template slot="table-columns">
        <el-table-column prop="nickname" label="用户名" min-width="200"/>
        <el-table-column prop="uname" label="登录账号"/>
        <el-table-column prop="role" label="角色"/>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleEditShopAssistant(scope.$index, scope.row)"
            >编辑
            </el-button>
            <!-- user_state === -1 禁用  0 正常 -->
            <!-- <el-button
              size="mini"
              type="danger"
              v-if="scope.row.role_id !== 0"
              @click="handleDeleteShopAssistant(scope.$index, scope.row)"
              >删除</el-button
            > -->
            <el-button
              size="mini"
              type="danger"
              v-if="scope.row.user_state === 0 && scope.row.role_id !== 0"
              @click="handleDeleteShopAssistant(scope.$index, scope.row)"
            >禁用
            </el-button>
            <el-button
              size="mini"
              type="success"
              v-if="scope.row.user_state === -1"
              @click="handleRecoveryShopAssistant(scope.$index, scope.row)"
            >启用
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <!--编辑-->
    <el-dialog
      title="编辑/添加"
      :visible.sync="editDialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
    >
      <el-form
        :model="shopAssistantForm"
        :rules="shopAssistantRules"
        ref="shopAssistantFormRef"
        label-width="100px"
      >
        <el-form-item label="用户名" prop="nickname">
          <el-input
            v-model="shopAssistantForm.nickname"
            :maxlength="20"
            clearable
            placeholder="请输入用户名"
          ></el-input>
          <!-- mobile  -->
        </el-form-item>
        <el-form-item label="登录账号" prop="uname">
          <el-input
            v-model="shopAssistantForm.uname"
            :maxlength="20"
            clearable
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input
            v-model="shopAssistantForm.mobile"
            :maxlength="11"
            clearable
            autocomplete="off"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="输入密码" prop="password">
          <el-input
            v-model="shopAssistantForm.password"
            clearable
            placeholder="请输入密码"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password1">
          <el-input
            v-model="shopAssistantForm.password1"
            clearable
            placeholder="请确认密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型" v-if="shopAssistantForm.role_id !== 0">
          <el-radio-group v-model="shopAssistantForm.founder">
            <el-radio :label="0">普通管理员</el-radio>
            <!-- <el-radio :label="1">超级管理员</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="shopAssistantForm.user_state">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="-1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input
            v-model="shopAssistantForm.nickname"
            clearable
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="编号">
          <el-input v-model="shopAssistantForm.member_id" clearable placeholder="请输入编号"></el-input>
        </el-form-item>-->
        <el-form-item
          label="角色"
          prop="role_id"
          v-if="shopAssistantForm.role_id !== 0"
        >
          <el-select
            v-model="shopAssistantForm.role_id"
            placeholder="请选择角色"
          >
            <el-option
              v-for="item in rolesOptions"
              :key="item.role_id"
              :value="item.role_id"
              :label="item.role_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="shopAssistantForm.remark"
            clearable
            placeholder="备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="editShopAssistantForm" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as API_Auth from '@/api/auth';
import * as API_Shop from '@/api/shop';
import * as API_Common from '@/api/common';
import md5 from 'js-md5';
import Storage from '@/utils/storage';
import {CountDownBtn} from '@/components/';
import {RegExp} from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'shopAssistant',
  components: {
    EnTableLayout,
    [CountDownBtn.name]: CountDownBtn,
  },
  data() {
    return {
      radio1: '',
      radio: '',
      radio3: false,
      uuid: Storage.getItem('seller_uuid'),
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        // disabled: 0,
      },
      // 列表数据
      tableData: {data: [1, 2]},
      // 店员手机表单
      shopAssistantPhoneForm: {},
      // 店员手机表单 规则
      shopAssistantPhoneRules: {
        mobile: [
          this.MixinRequired('请输入手机号码！'),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error('手机号码格式有误！'));
              } else {
                callback();
              }
            },
          },
        ],
        captcha: [this.MixinRequired('请输入图片验证码！')],
        sms_code: [this.MixinRequired('请输入短信验证码！')],
      },
      // 店员编辑表单
      shopAssistantForm: {},
      // 店员表单 规则
      shopAssistantRules: {
        uname: [
          { required: true, message: '请输入登录账号', trigger: 'blur'}
        ],
        nickname: [
          { required: true, message: '请输入用户名！', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value && !value.trim()) {
                callback(new Error('请输入用户名,且不能为空格！'));
              }
              // else if (!/^[0-9A-Za-z]{6,20}$/.test(value)) {
              //   // /^(?![0-9]+$)[\u4e00-\u9fa5_0-9A-Za-z]{6,20}$/
              //   callback(
              //     new Error('用户名名称不可为汉字，长度6-20个字符，不允许特殊符号！')
              //   );
              // }
              else {
                callback();
              }
            },
            trigger: 'blur'
          },
        ],
        password: [
          {
            required: true,
            message: '请输入店员密码！',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (
                this.shopAssistantForm.clerk_id &&
                !RegExp.password.test(value)
              ) {
                callback(new Error('密码格式错误！'));
              } else {
                callback();
              }
            },
          },
        ],
        password1: [
          {
            required: true,
            message: '请输入店员密码！',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (
                this.shopAssistantForm.clerk_id &&
                !RegExp.password.test(value)
              ) {
                callback(new Error('密码格式错误！'));
              } else if (this.shopAssistantForm.password !== value) {
                callback(new Error('密码输入不一致！'));
              } else {
                callback();
              }
            },
          },
        ],

        role_id: [
          {
            required: true,
            message: '请选择所属角色！',
            trigger: 'change'
          },
          // {
          //   validator: (rule, value, callback) => {
          //     const _result = this.tableData.data.findIndex(val => {
          //       return val.role_id === 0;
          //     });
          //     if (_result !== -1 && value === 0) {
          //       callback(new Error("超级店员只能添加一个！"));
          //     } else {
          //       callback();
          //     }
          //   }
          // }
        ],
        mobile: [
          this.MixinRequired('请输入手机号码！'),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error('手机号码格式有误！'));
              } else {
                callback();
              }
            },
          },
        ],
      },
      // 店员表单 dialog
      dialogVisible: false,
      // 编辑店员表单
      editDialogVisible: false,
      // 角色列表
      rolesOptions: [],
      // 验证码图片
      validcodeImg: '',
      // 当前步骤 默认第一步
      cur_step: 1,
      // 新店员还是老店员 1为老店员 0为新店员 默认老店员
      member_role: 1,
      // 是否停止计时器
      isStop: false,
      // 是否是编辑模式 默认不是
      isEdit: false,
    };
  },
  watch: {
    // 'shopAssistantForm.clerk_id': function (newValue) {
    //   this.shopAssistantRules.password[0].required = !newValue;
    // },
    dialogVisible() {
      if (!this.dialogVisible) {
        this.isStop = false;
      }
    },
  },
  mounted() {
    this.GET_ShopAssistantList();
    // 获取角色
    API_Auth.getRoleList({page_size: 20000}).then((response) => {
      this.rolesOptions = response.data;
    });
    this.changeValidcode();
  },
  activated() {
    // 获取角色
    API_Auth.getRoleList({page_size: 20000}).then((response) => {
      this.rolesOptions = response.data;
    });
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_ShopAssistantList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_ShopAssistantList();
    },

    /** 添加店员 */
    async handleAddShopAssistant() {
      const res = await API_Shop.checkShopLv();

      if (res.data.isClerk !== '1') {
        return this.$message.error('管理员数量达到上限');
      }

      this.editDialogVisible = true;
      this.shopAssistantForm = {
        user_state: 0,
        founder: 0,
        role_id: null,
      };
      // 停止计时器
      this.isStop = true;
      // 设置添加模式
      this.isEdit = false;
    },

    /** 更换图片验证码 */
    changeValidcode() {
      this.validcodeImg = API_Common.getValidateCodeUrl('ADD_CLERK', this.uuid);
    },

    /** 发送手机验证码异步方法 */
    // sendValidMobileSms() {
    //   return new Promise((resolve, reject) => {
    //     const { mobile, captcha } = this.shopAssistantPhoneForm;
    //     if (!mobile) {
    //       this.$message.error("请输入手机号！");
    //     } else if (!captcha) {
    //       this.$message.error("请输入图片验证码！");
    //     } else {
    //       API_Auth.getSmsCode(mobile, { captcha: captcha, uuid: this.uuid })
    //         .then(() => {
    //           this.$message.success("短信发送成功，请注意查收！");
    //           resolve();
    //         })
    //         .catch(reject);
    //     }
    //   });
    // },

    /** 下一步 */
    // toNext() {
    //   this.$refs["shopAssistantPhoneForm"].validate(valid => {
    //     if (valid) {
    //       // 对手机号码进行校验
    //       const { mobile, sms_code } = this.shopAssistantPhoneForm;
    //       API_Auth.valiPhone(mobile, { sms_code })
    //         .then(response => {
    //           this.member_role = response.result === "exist" ? 1 : 0;
    //           this.cur_step = 2;
    //           this.shopAddAssistantForm.mobile = response.mobile;
    //         })
    //         .catch();
    //     } else {
    //       this.$message.error("表单填写有误，请核对！");
    //       return false;
    //     }
    //   });
    // },

    /** 编辑店员 */
    handleEditShopAssistant(index, row) {
      this.shopAssistantForm = {
        ...row,
      };
      this.editDialogVisible = true;
      // 设置编辑模式
      this.isEdit = true;
    },

    /** 删除店员 */
    handleDeleteShopAssistant(index, row) {
      this.$confirm('确定要禁用这个店员吗？', '提示', {type: 'warning'})
        .then(() => {
          API_Auth.deleteShopAssistant(row.clerk_id).then(() => {
            this.$message.success('禁用成功！');
            this.GET_ShopAssistantList();
          });
        })
        .catch(() => {
        });
    },

    /** 恢复被禁用店员 */
    handleRecoveryShopAssistant(index, row) {
      this.$confirm('确定要启用这个店员吗？', '提示', {type: 'warning'})
        .then(() => {
          API_Auth.recoveryShopAssistant(row.clerk_id).then(() => {
            this.$message.success('启用成功！');
            this.GET_ShopAssistantList();
          });
        })
        .catch(() => {
        });
    },

    /** dialog打开后重置form表单校验结果 */
    handleDialogOpen() {
      // if (this.isEdit) {
      //   setTimeout(() => {
      //     this.$refs.shopAssistantFormRef.clearValidate();
      //   });
      // } else {
      //   // setTimeout(() => {
      //   //   this.$refs["shopAssistantPhoneForm"].resetFields();
      //   // });
      //   setTimeout(() => {
      //     this.$refs.shopAssistantFormRef.resetFields();
      //   });
      //   this.cur_step = 1;
      // }
    },

    /** 添加店员表单 */
    addShopAssistantForm() {
      this.$refs.shopAssistantFormRef.validate((valid) => {
        if (valid) {
          const params = this.MixinClone(this.shopAssistantForm);
          if (params.password) params.password = md5(params.password);
          if (this.member_role === 1) {
            // 老会员
            API_Auth.addOldMember(params).then(() => {
              this.dialogVisible = false;
              this.$message.success('添加成功！');
              this.GET_ShopAssistantList();
              this.isStop = false;
            });
          } else {
            // 新会员
            console.log(params);
            API_Auth.addNewMember(params).then(() => {
              this.dialogVisible = false;
              this.$message.success('添加成功！');
              this.GET_ShopAssistantList();
              this.isStop = false;
            });
          }
        } else {
          this.$message.error('表单填写有误，请核对！');
          return false;
        }
      });
    },

    /** 编辑店员表单 */
    editShopAssistantForm() {
      this.$refs.shopAssistantFormRef.validate((valid) => {
        if (valid) {
          const params = this.MixinClone(this.shopAssistantForm);
          delete params.password1

          const {clerk_id} = this.shopAssistantForm;

          if (params.password) params.password = md5(params.password);
          if (clerk_id) {
            // console.log('编辑');
            API_Auth.editShopAssistant(clerk_id, params).then(() => {
              this.editDialogVisible = false;
              this.$message.success('修改成功！');
              this.$refs.shopAssistantFormRef.resetFields();
              this.GET_ShopAssistantList();
            });
          } else {
            // console.log('添加');
            API_Auth.addNewMember(params).then(() => {
              this.editDialogVisible = false;
              this.$message.success('添加成功！');
              this.$refs.shopAssistantFormRef.resetFields();
              this.GET_ShopAssistantList();
            });
          }
        } else {
          this.$message.error('表单填写有误，请核对！');
          return false;
        }
      });
    },

    /** 获取店员列表 */
    GET_ShopAssistantList() {
      this.loading = true;
      API_Auth.getShopAssistantList(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.face-image {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}

/deep/ .img-code {
  width: 80%;

  .el-input-group__append {
    padding: 0;
    margin: 0;

    img {
      display: block;
      height: 30px;
      cursor: pointer;
    }
  }
}
</style>
